<template>
  <div class="">
    <div class="my-5">
      <div class="row mb-4">
        <div class="col-12 col-md-6">
          <div class="text-start" style="margin-bottom: 2rem;">
            <label for="course" class="form-label d-block w-100 text-start">選擇課程</label>
            <div style="background: white;">
              <v-select
                label="label"
                :options="courseList"
                v-model="selectedCourses"
                :placeholder="'請選擇課程'"
                @option:selected="courseSelected">
                <div slot="no-options">找不到結果</div>
              </v-select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="text-start" style="margin-bottom: 2rem;">
            <label for="year" class="form-label d-block w-100 text-start">選擇年度</label>
            <div style="background: white;">
              <v-select
                label="label"
                :options="years"
                v-model="selectedYear"
                :searchable="false"
                :placeholder="'請選擇年度'">
                <div slot="no-options">找不到結果</div>
              </v-select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="text-start" style="margin-bottom: 1rem;">
            <label for="quarter" class="form-label d-block w-100 text-start">選擇季度</label>
            <div style="background: white;">
              <v-select
                label="label"
                :options="quarters"
                v-model="quarter"
                :searchable="false"
                :placeholder="'請選擇季度'">
                <div slot="no-options">找不到結果</div>
              </v-select>
            </div>
          </div>
        </div>
        <div class="col-12 text-end">
            <button class="btn btn-main" type="submit"
              @click.prevent="submit"
              :disabled="!courseDropped || !selectedCourses || !quarter"
            >產出報表</button>
          </div>
      </div>
      <div v-if="submitted" class="mt-5">
        <vue-json-to-csv 
        :json-data="data"
        :labels="{
          id: { title: '編號'},
          title: { title: '名稱'},
          price: { title: '定價'},
          partner: { title: '通路'},
          percent: { title: '百分比'},
          profit: { title: '金額'},
        }"
      >
        <button class="btn btn-main d-inline-block">
          <b>輸出</b>
        </button>
        </vue-json-to-csv>
        <table class="table mt-4">
          <thead>
            <tr>
              <th>名稱</th>
              <th width="150">定價</th>
              <th width="150">通路</th>
              <th width="140">百分比</th>
              <th width="120">金額</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,key) in data" :key="key">
              <td>{{item.title}}</td>
              <td>{{item.price}}</td>
              <td>{{item.partner}}</td>
              <td>{{item.percent}}%</td>
              <td>{{item.profit}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
/* global $ */
import { mapActions, mapGetters } from 'vuex';
import { VueGoodTable } from 'vue-good-table';
import VueJsonToCsv from 'vue-json-to-csv';
import 'vue-good-table/dist/vue-good-table.css';
import 'vue-select/dist/vue-select.css';

const Swal = require('sweetalert2');

export default {
  name: 'Admin__Report',
  components: {
    VueGoodTable,
    VueJsonToCsv
  },
  data() {
    return {
      page: 1,
      data: [],
      noData: false,
      tempCourse: {},
      is_enabled: true,
      createModalRole: '系統管理員',
      fileUploading: '',
      teacherList: [],
      courseList: [],
      selectedCourses: [],
      metaTitle: '報表 / 訂單 / 管理 - 元學堂',
      courseDropped: false,
      submitted: false,
      quarter: '',
      years: [],
      selectedYear: '',
      quarters: [
        { label: '第一季', value: '1'},
        { label: '第二季', value: '2'},
        { label: '第三季', value: '3'},
        { label: '第四季', value: '4'},
      ],
      data: [
        {
          id: 0, title: 'pp', price: '300', partner: 'uee', percent: '6', profit: '200'
        },
        {
          id: 1, title: 'pp', price: '300', partner: 'uee', percent: '6', profit: '200'
        },
      ],
    };
  },
  metaInfo: {
    title: "報表 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '報表 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    getData() {
      this.$http.get(`${this.$API_PATH}/Admin/CourseManagement/Manual`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        this.courseList = res.data.data.courseList.map((item) => ({ ...item, label: `${item.id} - ${item.name}` }));
        // console.log(this.courseList, this.studentList);
      }).catch((err) => {
        // this.$router.push('/error');
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '權限不足',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/');
            }
          });
        }
      });
    },
    switchPage(num) {
      this.page += num;
      if (this.page < 1) {
        this.page = 1;
      }
      if (this.noData) {

      }
      this.getData();
    },
    getYears() {
      const today = new Date();
      let year = today.getFullYear();
      do{
        this.years.push(year);
        year--;
      } while (year >= 2022);
      this.years.reverse();
    },
    handleModalRole(str) {
      this.createModalRole = str;
      this.fileUploading = '';
    },
    courseSelected(opt) {
      this.courseDropped = true;
    },
    submit() {
      // gen report api
      this.submitted = true;
      // this.data = "";
      this.$http.get(`${this.$API_PATH}/Merchant/SalesChannel/Report?
      Annual=${this.selectedYear}&Quater=${this.quarter.value}&CourseId=${this.selectedCourses.id}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.updateLoading(false);
        // console.log(res.data.data);
      }).catch((err) => {
        this.updateLoading(false);
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '無權限取得列表',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '',
            '錯誤，請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              vm.$router.push('/');
            }
          });
        }
      });
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  created() {
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    // console.log(this.userAuth);
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    }
    this.getData();
    this.getYears();
  },
};
</script>

<style lang="scss" scoped>
  .pageNum {
    cursor: pointer;
    text-decoration: none;
    &.disabled {
      pointer-events: none;
      color: gray;
      cursor: default;
    }
  }
  ::v-deep #vgt-table tbody tr td:nth-of-type(1),
  ::v-deep #vgt-table tbody tr td:last-of-type,
  ::v-deep #vgt-table tbody tr td:nth-of-type(9) {
    text-align: center;
  }
  ::v-deep #vgt-table tbody tr td:last-of-type span {
    display: flex;
    flex-direction: column;
  }
  ::v-deep #vgt-table td span {
    line-height: 1.4rem;
  }

  ::v-deep #vgt-table thead th {
    min-width: 95px !important;
    padding-right: .75rem !important;
  }

  [id*="json-to-csv-"] {
    display: block;
    text-align: end;
  }
</style>
